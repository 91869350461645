<template>
  <lobby-common-logic :id="id" ref="commonLogic">
    <template #specific-form="{ data, collapse, images }">
      <div>
        <lobby-ap-form
            :prop-data="data"
            :collapse="collapse"
            :images="images"
            @update:data="updateSpecificData"
            @loadLobbyImages="loadLobbyImages"
        />
      </div>
    </template>
  </lobby-common-logic>
</template>

<script>
import LobbyCommonLogic from "@/views/components/whitelabel-templates/common/lobby/LobbyCommonLogin.vue";
import LobbyApForm from "@/views/components/whitelabel-templates/GodMoney/config/PageEdit/LobbyManagement/lobbyApForm.vue";

export default {
  name: "LobbyContainer",

  components: {
    LobbyApForm,
    LobbyCommonLogic,
  },

  data() {
    return {
      id: this.$route.params.id || null,
    };
  },

  methods: {
    updateSpecificData(specificData) {
      this.$refs.commonLogic.updateSpecificData(specificData);
    },
    loadLobbyImages() {
      this.$refs.commonLogic.loadLobbyImages();
    },
  },
};
</script>
