<script>
import {computed, onMounted, ref} from "@vue/composition-api";
import store from "@/store";
import {createCssVar} from "@/views/components/whitelabel-templates/common/utils/createCssVar";

export default {
  name: "GodMoneyFeaturedSelection",
  setup() {
    const editTemplate = computed(
      () => store.state["app-whitelabel-templates"].editTemplate
    );

    const featuredSelectionConfig = computed(
      () => editTemplate.value.templateData.featuredSelection
    );

    return {
      featuredSelectionConfig,
    };
  },
};
</script>

<template>
  <div ref="el" class="flex flex-col gap-16">
    <div class="text-lg font-bold text-white uppercase lg:text-3xl pl-1 py-2">
      {{ featuredSelectionConfig.title }}
    </div>
    <div class="grid gap-10 lg:grid-cols-2 d-flex justify-content-between">
      <a
        v-for="(item, index) in featuredSelectionConfig.items"
        :key="index"
        class="gm-hover-img-zoom"
      >
        <img :src="item.src" alt="featured-selection-image" />
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">

.text-lg {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  font-family: var(--body-font), sans-serif !important;
}

.gm-hover-img-zoom {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin: auto;

  img {
    transition: transform 0.5s;
  }

  &:hover img {
    transform: scale(1.1);
  }
}
</style>
