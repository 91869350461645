<script>
import {BButton, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BRow,} from "bootstrap-vue";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import vSelect from "vue-select";
import {ref} from "@vue/composition-api";
import IconDropdown from "@/views/components/whitelabel-templates/common/IconDropDown.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {useToast} from "vue-toastification/composition";

const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "tableForm",
  components: {
    ImageFieldForm,
    IconDropdown,
    BRow,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BCol,
    vSelect,
  },
  props: {
    template: {
      type: Object,
      default: () => ({}),
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    table: {
      type: Object,
      default: () => ({
        link: "",
        src: "",
      }),
    },
  },
  setup(props, {emit}) {
    const backURL = ref(BACK_URL);
    const formValid = ref(false);
    const toast = useToast();

    const onSubmit = () => {
      if (props.settings.action === "edit") {
        emit("update");
      } else {
        emit("save");
      }
    };

    const onCancel = () => {
      emit("reset");
    };

    const tableButtonHandler = () => {
      emit(props.settings.action === "edit" ? "update" : "save");
    };


    const {refTableFormObserver, getValidationState, resetForm} =
        formValidation(() => {
        });

    const onUploadedImage = (data) => {
      if (data?.files.length) {
        props.table.src = data.files[data.files.length - 1]
      }
    }

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }

    return {
      formValid,
      onCancel,
      tableButtonHandler,
      refTableFormObserver,
      getValidationState,
      resetForm,
      onSubmit,
      onUploadedImage,
      errorInLoadImage
    };
  },
};
</script>

<template>
  <validation-observer
      ref="refTableFormObserver"
      #default="{ handleSubmit, invalid }"
  >
    <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
      <b-list-group-item style="background: none" class="px-0">

        <b-row>
          <validation-provider
              #default="validationContext"
              name="link"
              rules="required"
              class="w-100"
          >
            <b-col cols="12">
              <b-form-group :label="$t('labels.link')" label-for="link">
                <b-form-input
                    id="link"
                    v-model="table.link"
                    :state="getValidationState(validationContext)"
                    required
                />
                <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row>
          <span>184x368</span>
              <div class="px-1 mt-1">
                <image-field-form
                    size-validation="184x368"
                    :single-button="true"
                    class="w-100"
                    text="Logo"
                    :value="table.src"
                    path="live_tables"
                    @uploadedImage="onUploadedImage($event, 'image')"
                    @error="errorInLoadImage"
                    preview="true"
                >
                </image-field-form>
              </div>
        </b-row>


      </b-list-group-item>

      <div class="mt-4 d-flex flex-row" style="gap: 5px">
        <b-button
            :disabled="invalid"
            type="submit"
            variant="primary"
        >
          {{
            settings.action === "edit"
                ? $t("buttons.update_and_apply")
                : $t("buttons.create_and_apply")
          }}
        </b-button>
        <b-button @click="onCancel" variant="secondary">{{
            $t("buttons.cancel")
          }}
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<style scoped lang="scss"></style>
