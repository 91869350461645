<script>
import { computed } from "@vue/composition-api";
import store from "@/store";

export default {
  name: "GodMoneySportBetting",
  setup() {
    const editTemplate = computed(
      () => store.state["app-whitelabel-templates"].editTemplate
    );

    const sportsBettingConfig = computed(
      () => editTemplate.value.templateData.sportsBetting
    );

    return {
      sportsBettingConfig,
    };
  },
};
</script>

<template>
  <div class="flex flex-col gap-16">
    <div class="text-lg font-bold text-white uppercase lg:text-3xl">
      <a>{{ sportsBettingConfig.title }}</a>
    </div>
    <a
      v-for="(item, index) in sportsBettingConfig.items"
      class="d-flex"
      :key="index"
    >
      <img class="mx-auto" :src="item.src" alt="sport-betting-images" />
    </a>
  </div>
</template>

<style scoped lang="scss"></style>
