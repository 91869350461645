<script>
import { computed, onMounted, ref, watch } from "@vue/composition-api";
import store from "@/store";
import { createCssVar } from "@/views/components/whitelabel-templates/common/utils/createCssVar";
import UserProfile from "@/views/components/whitelabel-templates/common/UserProfile/UserProfile.vue";
import GodMoneyHeader from "@/views/components/whitelabel-templates/GodMoney/components/common/GodMoneyHeader.vue";
import GodMoneyFooter from "@/views/components/whitelabel-templates/GodMoney/components/common/footer/GodMoneyFooter.vue";
import GodMoneyResponsibleGaming from "@/views/components/whitelabel-templates/GodMoney/components/section/responsible-gaming/GodMoneyResponsibleGaming.vue";
import gmStoreModule from "@/views/components/whitelabel-templates/GodMoney/store/gmStoreModule";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import GodMoneyHomePage from "@/views/components/whitelabel-templates/GodMoney/views/GodMoneyHomePage.vue";

const MODULE_KB_STORE_NAME = "gm-store";
const MODULE_APP_STORE_NAME = "app-whitelabel-templates";

export default {
  name: "GodMoneyTemplate",
  components: {
    GodMoneyHomePage,
    GodMoneyResponsibleGaming,
    GodMoneyFooter,
    GodMoneyHeader,
    UserProfile,
  },
  setup() {
    // Register modules
    if (!store.hasModule(MODULE_KB_STORE_NAME))
      store.registerModule(MODULE_KB_STORE_NAME, gmStoreModule);
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);

    const el = ref(null);

    const editTemplate = computed(
      () => store.state["app-whitelabel-templates"].editTemplate
    );

    const  backgroundImageStyle = computed( () => {
      const bgImage = editTemplate.value.templateData.main.backgroundImage
      let style = {}

      if (bgImage) {
        style = {
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat'
        }
      }

      return style
    })

    const preview = computed(() => store.state["gm-store"].preview);

    const headerConfig = computed(() => editTemplate.value.templateData.header);
    const footerConfig = computed(() => editTemplate.value.templateData.footer);
    const menuConfig = computed(() => editTemplate.value.templateData.menu);
    const responsibleGamingConfig = computed(
      () => editTemplate.value.templateData.responsible_gaming
    );

    const mainColors = computed(
      () => editTemplate.value.templateData.main.colors
    );

    const userProfileColors = computed(
        () => editTemplate.value.templateData.user_profile.colors
    );

    const mainFont = computed(() => editTemplate.value.templateData.main.font);

    const setCssVar = () => {
      if (!el.value) return;
      createCssVar(el.value, {
        "--primary-color": mainColors.value.primaryColor,
        "--header-bg": headerConfig.value.background_color,
        "--header-c": headerConfig.value.color,
        "--body-font": mainFont.value.font,
        "--body-color": mainColors.value.bodyColor,
        "--background-color": mainColors.value.backgroundColor,
        "--placeholder-color": mainColors.value.placeHolderColor,
        "--secondary-color": mainColors.value.secondaryColor,
        "--secondary-alt-color": mainColors.value.secondaryAltColor,
        "--para-color": mainColors.value.paraColor,
        "--para-alt-color": mainColors.value.paraAltColor,
        "--section-color": mainColors.value.sectionColor,
        "--bs-white": mainColors.value.bsWhite,
        "--text-color": mainColors.value.textColor,
        "--setting-bg-primary": userProfileColors.value.bgPrimary,
        "--setting-bg-secondary": userProfileColors.value.bgSecondary,
        "--setting-color-primary": userProfileColors.value.colorPrimary,
        "--setting-color-primary-text": userProfileColors.value.colorPrimaryText,
        "--setting-color-secondary": userProfileColors.value.colorSecondary,
      });
    };

    watch([mainColors, headerConfig, menuConfig, footerConfig, userProfileColors], setCssVar, {
      deep: true,
      immediate: true,
    });

    onMounted(() => {
      setCssVar();
      store.commit("setVuetifyTheme", false);
    });

    return {
      backgroundImageStyle,
      el,
      headerConfig,
      footerConfig,
      menuConfig,
      preview,
      responsibleGamingConfig,
    };
  },
};
</script>

<template>
  <main id="godmoney-template" ref="el">
    <div class="bg-image" :style="backgroundImageStyle">
    <god-money-header
      v-if="headerConfig"
      :header="headerConfig"
      :menu="menuConfig"
    />
    <user-profile v-show="preview === 'profile'" />
    <god-money-home-page v-show="preview === 'general'" />
    </div>
    <god-money-footer v-if="footerConfig" :footer="footerConfig" />
    <god-money-responsible-gaming
      v-if="responsibleGamingConfig"
      :responsible_gaming="responsibleGamingConfig"
    />
  </main>
</template>

<style lang="scss">
@import "assets/styles";

html {
  scroll-behavior: smooth;
}

#godmoney-template {
  font-family: var(--body-font) !important;
  background-color: var(--body-color);
  font-size: 16px;
  line-height: 30px;
  margin: 0;
  font-weight: 400;
  overflow-x: hidden;
  letter-spacing: 0 !important;
  padding: 50px 0 0 0;

  @media (min-width: 1024px) {
    padding-top: 0;
  }
}
</style>
