<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { computed, ref, onMounted } from "@vue/composition-api";
import store from "@/store";
import axios from "@/libs/axios";
import IconDropdown from "@/views/components/whitelabel-templates/common/IconDropDown.vue";
import { svgs } from "@/views/components/whitelabel-templates/GodMoney/assets/svgs";
import formValidation from "@core/comp-functions/forms/form-validation";

const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "LinkForm",
  components: {
    IconDropdown,
    BRow,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BCol,
    vSelect,
  },
  props: {
    template: {
      type: Object,
      default: () => ({}),
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    link: {
      type: Object,
      default: () => ({
        svgData: "",
        href: "",
      }),
    },
  },
  setup(props, { emit }) {
    const options = ref(svgs);
    const backURL = ref(BACK_URL);
    const formValid = ref(false);


    const onSubmit = () => {
      if (props.settings.action === "edit") {
        emit("update");
      } else {
        emit("save");
      }
    };

    const onCancel = () => {
      emit("reset");
      props.settings.index = null
    };

    const linkButtonHandler = () => {
      emit(props.settings.action === "edit" ? "update" : "save");
    };

    const onIconSelected = (icon) => {
      props.link.svgData = icon.value;
      emit("iconSelected");
    };

    const { refLinkFormObserver, getValidationState, resetForm } =
        formValidation(() => {});

    return {
      options,
      formValid,
      onCancel,
      linkButtonHandler,
      onIconSelected,
      refLinkFormObserver,
      getValidationState,
      resetForm,
      onSubmit,
    };
  },
};
</script>

<template>
  <validation-observer
      ref="refLinkFormObserver"
      #default="{ handleSubmit, invalid }"
  >
    <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
      <b-list-group-item style="background: none" class="px-0">

        <b-row>
          <validation-provider
              #default="validationContext"
              name="href"
              rules="required"
              class="w-100"
          >
            <b-col cols="12">
              <b-form-group :label="$t('labels.link')" label-for="href">
                <b-form-input
                    id="href"
                    v-model="link.href"
                    :state="getValidationState(validationContext)"
                    required
                />
                <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>


        <b-row>
          <b-col cols="12">
            <b-form-group label="Icon" label-for="icon">
              <b-input-group>
                <IconDropdown
                    :icons="options"
                    :selected-icon-prop="link.svgData"
                    @icon-selected="onIconSelected($event, link)"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-list-group-item>

      <div class="mt-4 d-flex flex-row" style="gap: 5px">
        <b-button
            :disabled="invalid"
            type="submit"
            variant="primary"
        >
          {{
            settings.action === "edit"
                ? $t("buttons.update_and_apply")
                : $t("buttons.create_and_apply")
          }}
        </b-button>
        <b-button @click="onCancel" variant="secondary">{{
            $t("buttons.cancel")
          }}</b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<style scoped lang="scss"></style>
