<script>
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {BButton, BCol, BForm, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import store from "@/store";
import {showErrorToast} from "@/views/components/whitelabel-templates/common/utils/showToast";
import i18n from "@/libs/i18n";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {useToast} from "vue-toastification/composition";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "extra-edit",
  components: {
    BRow,
    ImageFieldForm,
    BButton,
    BFormInput,
    BFormGroup,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BForm
  },

  setup() {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    const toast = useToast();

    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);

    const {refFormObserver, getValidationState, resetForm} = formValidation(
        () => {
        }
    );

    const image = ref('')

    const editTemplate = computed(
        () => store.state[MODULE_APP_STORE_NAME].editTemplate
    );

    onMounted(() => {
      image.value = editTemplate.value.templateData.main.backgroundImage
    })

    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
            "app-whitelabel-templates/updateTemplate",
            {
              template: editTemplate.value,
              toast,
              path: 'templateData.main.backgroundImag'
            }
        );

      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    }

    const onSubmit = () => {
      editTemplate.value.templateData.main.backgroundImage = image.value
      updateTemplate()
    }

    const onUploadedImage = (data) => {
      if (data?.files.length) {
        image.value = data.files[data.files.length - 1];
      }
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const resetData = () => {
      image.value = ''
    }

    return {
      image,
      onSubmit,
      resetData,
      refFormObserver,
      getValidationState,
      resetForm,
      editTemplate,
      updateTemplate,
      ValidationProvider,
      ValidationObserver,
      onUploadedImage,
      errorInLoadImage
    }
  }
}
</script>

<template>

  <validation-observer
      ref="refFormObserver"
      #default="{ handleSubmit, invalid }"
  >
    <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
    >
      <b-row>
        <div class="px-1 w-100 mt-1">
          <b-form-group label="Background Image">
            <template #label>Background Image</template>
            <image-field-form
                :single-button="true"
                class="w-100"
                text="Images"
                :value="image"
                path="main"
                @uploadedImage="onUploadedImage($event)"
                @error="errorInLoadImage"
                :preview="false">
            </image-field-form>

            <div class="row" v-if="image">
                <img
                    style="max-height: 250px"
                    :src="image"
                    alt="Uploaded Image"
                    class="mt-1 rounded mx-auto"
                />
                <b-button class="close-btn" @click="resetData()" variant="outline-primary" size="sm">
                  X
                </b-button>
            </div>
          </b-form-group>
        </div>
      </b-row>

      <div class="mt-4 d-flex flex-column" style="gap: 5px">
        <b-button
            size="md"
            type="submit"
            class="w-100"
            variant="success"
        >
          {{ $t('buttons.save') }}
        </b-button>
        <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
      </div>
    </b-form>
  </validation-observer>


</template>

<style scoped lang="scss">
.close-btn {
  float: right;
  top: 5px;
  margin: 10px;
  height: 40px;
}
</style>