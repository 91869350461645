<script>
import { computed } from "@vue/composition-api";
import store from "@/store";

export default {
  name: "GodMoneyLiveTables",
  setup() {
    const editTemplate = computed(
      () => store.state["app-whitelabel-templates"].editTemplate
    );

    const liveTablesConfig = computed(
      () => editTemplate.value.templateData.liveTables
    );

    return {
      liveTablesConfig,
    };
  },
};
</script>

<template>
  <div class="container flex flex-col gap-10 mt-16 lg:mt-36">
    <div class="px-3 text-lg font-bold text-white uppercase lg:text-3xl">
      <a>{{ liveTablesConfig.title }}</a>
    </div>

    <div class="grid grid-cols-3 gap-3 lg:grid-cols-5">
      <a
        v-for="(table, index) in liveTablesConfig.tables"
        :key="index"
        :href="table.link"
      >
        <img :src="table.src" alt="live-tables-image" />
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
