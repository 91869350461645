<script>
export default {
  name: "GodMoneyFooter",
  props: {
    footer: {
      type: Object,
    },
  },

  computed: {
    footerStyle() {
      return {
        "--primary-color": this.footer.primary_color,
        backgroundImage: this.footer.background_image
          ? `url('${this.footer.background_image}')`
          : "",
        backgroundColor: this.footer.background_color || "#000",
        color: this.footer.color || "#fff",
      };
    },
  },
};
</script>

<template>
  <div :style="footerStyle" class="god-money-footer">
    <div class="god-money-footer-left">
      <div class="navbar-brand">
        <a href="" v-if="footer.left.image">
          <img
            :src="footer.left.image"
            class="pure-img"
            style="width: 250px; max-width: 100%"
            alt=""
          />
        </a>
      </div>
      <p v-for="(i, index) in footer.left.items" :key="index">
        <a>{{ i.content }}</a>
      </p>

      <button class="mt-5 btn apostala-languages">
        Languages
      </button>

      <!--LANG COMPONENT-->
    </div>

    <div class="god-money-footer-right">
      <div
        v-for="(section, index) in footer.right.sections"
        :key="index"
        class="god-money-footer-section"
      >
        <div class="god-money-footer-section-title">
          <a>{{ section.title }}</a>
        </div>
        <a
          v-for="(i, index) in section.items"
          :key="index"
          class="god-money-footer-section-item"
        >
          <a :style="{color: footer.color}">{{ i.content }}</a>
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../common/assets/mixins";

.god-money-footer {
  background-color: #053d76;
  color: var(--text-color);
  padding: 50px 0 100px 0;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @include responsive(lg) {
    padding: 80px 0 180px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
  }

  &-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 14px;

    @include responsive(lg) {
      align-items: center;
    }

    p {
      margin: 0;
      font-family: var(--body-font), sans-serif;
    }
  }

  &-right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0 20px;

    @include responsive(lg) {
      grid-template-columns: repeat(3, 1fr);
      padding: 0;
    }
  }
  &-section {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-title {
      font-weight: 700;
      font-size: 22px;
      border-bottom: 1px solid var(--primary-color);
      width: 100px;
      font-family: var(--body-font), sans-serif;
    }

    &-item {
      font-size: 14px;
      cursor: pointer;
      transition: color 0.3s;
      color: var(--text-color);
      font-family: var(--body-font), sans-serif;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .apostala-languages {
    background-color: var(--primary-color);
    color: var(--primary-text);
    font-family: var(--body-font), sans-serif;
  }
}
</style>
