<template>
  <div class="godmoney-login">
    <b-button size="sm" :style="loginButtonConfig"> Login </b-button>
  </div>
</template>

<script>
/*eslint-disable*/
export default {
  name: "GodmoneyLogin",
  props: ["config"],
  computed: {
    loginButtonConfig() {
        return `
        color: ${this.config.color} !important;
        backgroundColor: ${this.config.background_color} !important;
        border: "none";`
    },
  },
};
</script>
<style scoped>
.disabled-link {
  pointer-events: none;
  opacity: 0.5;
}
</style>
<style lang="scss" scoped>
.godmoney-login {
  button {
    font-family: var(--body-font), sans-serif;

    .icon {
      transition: all 0.2s ease;
    }
    &:hover {
      filter: brightness(0.9);
      .icon {
        transform: translateX(1px) !important;
      }
    }
  }
}
</style>
