<script>
import {computed} from "@vue/composition-api";

export default {
  name: "tableList",
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
    tables: {
      default: []
    }
  },
  components: {},

  setup(props, {emit}) {

    const getButtonsCount = computed(() => {
      return props.tables.length || 0;
    });

    const onEdit = (item, index) => {
      props.settings.index = index
      emit('edit', item)
    }

    const onAdd = () => {
      emit('add')
    }

    const onDelete = (index) => {
      emit('delete', index)
    }


    return {
      onEdit,
      onAdd,
      onDelete,
      getButtonsCount
    }

  },


}

</script>

<template>
  <div>

    <b-list-group flush v-if="tables.length > 0">
      <b-list-group-item v-for="(table, index) in tables" :key="index" class="list-g">
        <div class="image" :style="`background-image: url('${table.src}')`"></div>
        <div class="l-content">
          <div class="text">
            <span class="name">{{ table.link }}</span>
          </div>
          <b-button-group>
            <b-button @click="onEdit(table, index)" variant="outline-secondary" class="border-0">
              <feather-icon icon="EditIcon"/>
            </b-button>
            <b-button @click="onDelete(index)" variant="outline-secondary" class="border-0">
              <feather-icon icon="TrashIcon"/>
            </b-button>
          </b-button-group>
        </div>

      </b-list-group-item>
    </b-list-group>

    <div class="d-flex mt-2 justify-content-end">
      <b-button-group class="w-100">
        <b-button :disabled="getButtonsCount >= 5" class="w-100" variant="outline-primary" @click="onAdd()">
          Add table
        </b-button>
        <b-button variant="primary">{{ getButtonsCount }}/5</b-button>
      </b-button-group>
    </div>
  </div>
</template>

<style scoped lang="scss">

.list-group-item {
  background: transparent !important;
  padding-inline: 0 !important;
  font-size: 12px;
}

.list-group-item p {
  margin-bottom: 0;
}

.btn-outline-secondary {
  padding: 5px 8px;
  border: none !important;
}

.list-g {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}

.l-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.name {
  font-weight: 800;
}


.image {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  margin-right: 15px;
  background: rgb(108, 107, 133) center no-repeat;
  background-size: cover;
}


</style>
