import axios from '@axios'

const APP_URL = process.env.VUE_APP_URL

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchRGamingItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/responsibleGamings`, {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        removeGamingItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${APP_URL}/responsibleGamings/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        addRGamingItem(ctx, rGamingData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${APP_URL}/responsibleGamings`,  rGamingData )
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        updateRGamingItem(ctx, { id, rGamingData }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${APP_URL}/responsibleGamings/${id}`, rGamingData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
