import axios from '@axios'

const APP_URL = process.env.VUE_APP_URL

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchSportsBettingItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/sportsBettings`, {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        removeSportsBettingItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${APP_URL}/sportsBettings/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        addSportsBettingItem(ctx, sportsBettingData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${APP_URL}/sportsBettings`,  sportsBettingData )
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        updateSportsBettingItem(ctx, { id, sportsBettingData }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${APP_URL}/sportsBettings/${id}`, sportsBettingData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
