import axios from '@axios'

const APP_URL = process.env.VUE_APP_URL

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchSlotGameItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/slotGames`, {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        removeSlotGameItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${APP_URL}/slotGames/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        addSlotGameItem(ctx, slotGameData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${APP_URL}/slotGames`,  slotGameData )
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        updateSlotGameItem(ctx, { id, slotGameData }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${APP_URL}/slotGames/${id}`, slotGameData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
