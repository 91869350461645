<template>
  <div class="godmoney-register">
    <b-button size="sm" :style="ButtonConfig"> Create account </b-button>
  </div>
</template>
<script>
export default {
  props: ["config"],
  computed: {
    ButtonConfig() {
      return `
        color: ${this.config.color} !important;
        backgroundColor: ${this.config.background_color} !important;
        border: "none";`
    },
  },
};
</script>
<style scoped lang="scss">

.godmoney-register {
  text-wrap: nowrap;

  button {
    font-family: var(--body-font), sans-serif;

    .icon {
      transition: all 0.2s ease;
    }
    &:hover {
      filter: brightness(0.9);
      .icon {
        transform: translateX(1px) !important;
      }
    }
  }
}
</style>
