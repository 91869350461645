<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import {useToast} from "vue-toastification/composition";
import store from "@/store";
import SportsBettingStoreModule from "./store/sportsBettingStoreModule";
import i18n from "@/libs/i18n";
import {RequestBuilder} from "@core/utils/requestBuilder";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {required} from "@core/utils/validations/validations";

export default {
  name: "index",
  components: {
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      type: Object,
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "responsible_gaming",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    const GM_SPORTS_BETTING_STORE_MODULE_NAME = "gm-sports-betting-store";
    const sportsBetting = ref({
      name: '',
      title: '',
      items: [],
    });


    const loading = ref(false);
    const toast = useToast();
    const rBuild = RequestBuilder();
    const debounceTimer = ref(null);

    // Register modules
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
    if (!store.hasModule(GM_SPORTS_BETTING_STORE_MODULE_NAME))
      store.registerModule(GM_SPORTS_BETTING_STORE_MODULE_NAME, SportsBettingStoreModule);

    onMounted(() => {
      resetSportsBetting();
      const {itemsSelected} = props.options;
      if (itemsSelected) sportsBetting.value = itemsSelected;
      updatePreview();
    });

    const editTemplate = computed(
        () => store.state[MODULE_APP_STORE_NAME].editTemplate
    );

    const isEditMode = computed(() => props.options.action === "edit");

    const onSubmit = () => {
      if (isEditMode.value) {
        updateSportsBetting();
      } else {
        createSportsBetting();
      }
    };

    const updateTemplate = async () => {
      try {
        await store.dispatch("app-whitelabel-templates/updateTemplate", {
          template: editTemplate.value,
          toast,
          path: "templateData.sportsBetting"
        });
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const createSportsBetting = async () => {
      loading.value = true;
      sportsBetting.value.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
      sportsBetting.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            "gm-sports-betting-store/addSportsBettingItem",
            rBuild.clean(sportsBetting.value)
        );

        showSuccessToast(toast, 'Sports Betting', `Sports Beting Item created`);
        updatePreview(response.data);
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("Error creating or updating Sports Betting item"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateSportsBetting = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch("gm-sports-betting-store/updateSportsBettingItem", {
          id: sportsBetting.value._id,
          sportsBettingData: rBuild.clean(sportsBetting.value),
        });

        showSuccessToast(toast, 'Sports Betting', 'Sports Betting created');
        updatePreview(response.data);
        await updateTemplate();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            'Error creating or updating Sports Betting',
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetSportsBetting = () => {
      sportsBetting.value = {
        name: '',
        title: '',
        items: [],
      };
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.responsible_gaming");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
    };

    const {refFormObserver, getValidationState, resetForm} = formValidation(
        () => {
        }
    );

    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          sportsBetting: value || sportsBetting.value
        }
      };
      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const onUploadedImage = (data) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        sportsBetting.value.items = [{src: image}]
        updatePreview();
      }
    };

    return {
      isEditMode,
      onSubmit,
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      sportsBetting,
      loading,
      updatePreview,
      errorInLoadImage,
      onUploadedImage
    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
      <b-form
          v-if="sportsBetting"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-tab title="Content" active>
          <b-list-group-item style="background: none" class="px-0">
            <b-row>
              <validation-provider
                  class="w-100"
                  #default="validationContext"
                  name="name"
                  rules="required"
              >
                <b-col cols="12">
                  <b-form-group label="title" label-for="h-title">
                    <template #label>{{ $t("labels.title") }}</template>
                    <b-form-input
                        id="h-name"
                        type="text"
                        v-model="sportsBetting.title"
                        @input="updatePreview()"
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </b-col>
              </validation-provider>
            </b-row>

            <b-row>
              <validation-provider
                  class="w-100"
                  #default="validationContext"
                  name="name"
                  rules="required"
              >
                <b-col cols="12">
                  <b-form-group label="name" label-for="h-name">
                    <template #label>{{ $t("labels.name") }}</template>
                    <b-form-input
                        id="h-name"
                        type="text"
                        v-model="sportsBetting.name"
                        @input="updatePreview()"
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </b-col>
              </validation-provider>
            </b-row>

            <b-row>
              <div class="px-1 w-100 mt-1">
                <span>1140x300</span>
                <image-field-form
                    :single-button="true"
                    class="w-100"
                    text="Images"
                    :value="sportsBetting.items"
                    path="sports_betting"
                    size-validation="1140x300"
                    @uploadedImage="onUploadedImage($event, 'items')"
                    @error="errorInLoadImage"
                    :preview="false"
                >
                </image-field-form>

                <div class="row" v-if="sportsBetting.items">
                  <div v-for="(item, index) in sportsBetting.items" :key="index" class="col-md-6">
                    <img
                        v-if="item"
                        style="max-width: 100%; height: 50%"
                        :src="item.src"
                        alt="Uploaded Image"
                        class="mt-1 rounded"
                    />
                  </div>
                </div>
              </div>
            </b-row>

          </b-list-group-item>

        </b-tab>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss"></style>
