<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("labels.show") }}</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("labels.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
              />
              <div>
                <b-button
                    variant="primary"
                    :to="{
                    name: 'gm-lobby-form-create',
                    query: {
                      edit: false,
                      templateId: templateID,
                      templateType: templateType,
                    },
                  }"
                >
                  <span class="text-nowrap">{{ $t("buttons.add") }}</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-overlay
          :show="loading"
          :variant="$store.state.appConfig.layout.skin"
          blur="2"
          class="p-50"
      >
        <b-table
            ref="refLobbyListTable"
            class="position-relative"
            :items="fetchLobby"
            responsive
            :fields="tableColumns"
            primary-key="_id"
            :sort-by.sync="sortBy"
            show-empty
            :empty-text="this.$t('messages.nomatching')"
            :sort-desc.sync="isSortDirDesc"
            style="min-height: 300px"
        >
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
                variant="primary"
                class="btn-icon rounded-circle mr-1"
                v-b-tooltip.hover.top="$t('tooltips.edit')"
                size="sm"
                :to="{
                name: 'gm-lobby-form',
                params: { id: data.item._id },
                query: {
                  edit: true,
                  templateId: templateID,
                  templateType: templateType,
                },
              }"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
                variant="primary"
                class="btn-icon rounded-circle mr-1"
                v-b-tooltip.hover.top="$t('tooltips.delete')"
                @click="removeLobby(data.item._id)"
                size="sm"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
            >{{ $t("labels.showing") }} {{ dataMeta.from }}
              {{ $t("labels.to") }} {{ dataMeta.to }} {{ $t("labels.of") }}
              {{ dataMeta.of }} {{ $t("labels.entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="totalLobby"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, computed } from "@vue/composition-api";
import lobbyStoreModule from "./store/lobbyStoreModule";
import useLobbyList from "./useLobbyList.js";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import { axiosErrorHandle } from "@core/utils/errorHandler";

export default {
  name: 'LobbyList',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    VBTooltip,
  },

  setup(props, { emit }) {
    const LOBBY_GM_STORE_MODULE_NAME = "gm-lobby";
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";

    // Register module
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
    if (!store.hasModule(LOBBY_GM_STORE_MODULE_NAME))
      store.registerModule(LOBBY_GM_STORE_MODULE_NAME, lobbyStoreModule);

    console.log(store._modulesNamespaceMap); // Verifica que el módulo esté registrado
    console.log(store._actions); // Verifica que la acción esté registrada

    const isAddNewLobbySidebarActive = ref(false);
    const isAddLinkLobbySidebarActive = ref(false);

    const editTemplate = computed(() => {
      return store.state["app-whitelabel-templates"].editTemplate;
    });

    const prepareUpdateOrCreate = (item = null, action = "edit") => {
      emit("updateOrCreate", { item, action });
    };

    const templateID = computed(() => {
      return editTemplate.value._id;
    });

    const templateType = computed(() => {
      return editTemplate.value.type;
    });

    const {
      fetchLobby,
      tableColumns,
      perPage,
      currentPage,
      totalLobby,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLobbyListTable,
      refetchData,
      selectLobby,
      recordDeleted,
      LobbyData,
      loading,
      resolveLobbyStatusVariant,
    } = useLobbyList();

    return {
      templateID,
      templateType,
      // Sidebar
      isAddNewLobbySidebarActive,
      isAddLinkLobbySidebarActive,
      fetchLobby,
      tableColumns,
      perPage,
      currentPage,
      totalLobby,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLobbyListTable,
      refetchData,
      recordDeleted,
      LobbyData,
      selectLobby,
      prepareUpdateOrCreate,
      loading,
      resolveLobbyStatusVariant,
    };
  },
  methods: {
    linkSidebar(item) {
      this.LobbyData = item;
      this.isAddLinkLobbySidebarActive = true;
    },
    async removeLobby(id) {
      try {
        await store.dispatch("gm-lobby/removeLobby", { id });
        this.recordDeleted = !this.recordDeleted;
        showSuccessToast(this.$toast, "Lobby", "Lobby removed");
        this.refetchData();
      } catch (error) {
        showErrorToast(this.$toast, "Error", axiosErrorHandle(error));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
