<script>
import GodMoneyHeroSwiper from "@/views/components/whitelabel-templates/GodMoney/components/section/GodMoneyHeroSwiper.vue";
import GodMoneyFeaturedSelection from "@/views/components/whitelabel-templates/GodMoney/components/section/GodMoneyFeaturedSelection.vue";
import GodMoneySportBetting from "@/views/components/whitelabel-templates/GodMoney/components/section/GodMoneySportBetting.vue";
import GodMoneySlotGames from "@/views/components/whitelabel-templates/GodMoney/components/section/GodMoneySlotGames.vue";
import GodMoneyLiveTables from "@/views/components/whitelabel-templates/GodMoney/components/section/GodMoneyLiveTables.vue";
import GodMoneySocialLinks from "@/views/components/whitelabel-templates/GodMoney/components/section/GodMoneySocialLinks.vue";

export default {
  components: {
    GodMoneySocialLinks,
    GodMoneyLiveTables,
    GodMoneySlotGames,
    GodMoneySportBetting,
    GodMoneyFeaturedSelection,
    GodMoneyHeroSwiper,
  },
};
</script>

<template>
  <div>
    <god-money-hero-swiper />

    <god-money-featured-selection />

    <god-money-sport-betting />

    <god-money-slot-games />

    <god-money-live-tables />

    <god-money-social-links />
  </div>
</template>

<style scoped lang="scss"></style>
