var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_c('b-tab',{attrs:{"title":_vm.$t('main'),"active":""},on:{"click":function($event){return _vm.changePreview('general')}}},[_c('div',{staticClass:"list-group list-group-flush"},_vm._l((_vm.colors.main),function(value,key){return _c('a',{key:key,staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.formatLabel(key)))])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":value},on:{"input":function (newValue) { return _vm.onColorChange({ key: key, value: newValue, section: 'main' }); }}})],1)])])}),0)]),_c('b-tab',{attrs:{"title":_vm.$t('user_profile')},on:{"click":function($event){return _vm.changePreview('profile')}}},[_c('div',{staticClass:"list-group list-group-flush"},[_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Background Primary")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.userProfile.bgPrimary},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'bgPrimary',
                        value: newValue,
                        section: 'userProfile',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Background Secondary")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.userProfile.bgSecondary},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'bgSecondary',
                        value: newValue,
                        section: 'userProfile',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Border Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.userProfile.colorBorder},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'colorBorder',
                        value: newValue,
                        section: 'userProfile',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Primary Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.userProfile.colorPrimary},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'colorPrimary',
                        value: newValue,
                        section: 'userProfile',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Text. Primary Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.userProfile.colorPrimaryText},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'colorPrimaryText',
                        value: newValue,
                        section: 'userProfile',
                      }); }}})],1)])]),_c('a',{staticClass:"list-group-item border-0 list-group-item-action d-flex gap-3",attrs:{"aria-current":"true"}},[_c('div',{staticClass:"d-flex gap-2 w-100 justify-content-between"},[_c('div',[_c('h6',{staticClass:"mt-1"},[_vm._v("Secondary Color")])]),_c('span',{staticClass:"color-box"},[_c('b-form-input',{attrs:{"type":"color","value":_vm.colors.userProfile.colorSecondary},on:{"input":function (newValue) { return _vm.onColorChange({
                        key: 'colorSecondary',
                        value: newValue,
                        section: 'userProfile',
                      }); }}})],1)])])])])],1)],1),_c('div',{staticClass:"row justify-content-end pt-4 pb-2"},[_c('div',{staticClass:"col-md-6"},[_c('b-button',{staticClass:"w-100",attrs:{"disabled":_vm.loading,"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.resetData()}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.cancel"))+" ")])],1),_c('div',{staticClass:"col-md-6"},[_c('b-button',{staticClass:"w-100",attrs:{"disabled":_vm.loading,"size":"sm","variant":"primary"},on:{"click":_vm.updateTemplate}},[_vm._v(" Guardar")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }