import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import {computed, ref, watch} from "@vue/composition-api";
import {useToast} from "vue-toastification/composition";
import {
    showErrorToast
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {axiosErrorHandle} from "@core/utils/errorHandler";

export default function useSportsBettingList() {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";

    // Register module
    if (!store.hasModule(MODULE_APP_STORE_NAME))
        store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);

    const toast = useToast();
    const refSportsBettingListTable = ref(null);
    const loading = ref(false);
    const perPage = ref(10);
    const totalSportsBettingItems = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(false);
    const recordDeleted = ref(false);

    const editTemplate = computed(
        () => store.state[MODULE_APP_STORE_NAME].editTemplate
    );


    const dataMeta = computed(() => {
        const localItemsCount = refSportsBettingListTable.value?.localItems.length || 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalSportsBettingItems.value,
        };
    });

    const refetchData = () => {
        refSportsBettingListTable.value?.refresh();
    };

    const isSelected = (id) => editTemplate.value.templateData?.sportsBetting._id === id;

    const findSelected = (id) => {
        return (
            refSportsBettingListTable.value?.localItems.find((item) => item._id === id) ||
            null
        );
    };

    const selectSportsBettingItem = async (id) => {
        const selected = findSelected(id);
        if (!selected) return;

        const updatedTemplate = {
            ...editTemplate.value,
            templateData: {
                ...editTemplate.value.templateData,
                sportsBetting: selected
            }
        };

        store.commit('app-whitelabel-templates/SET_EDIT_TEMPLATE', updatedTemplate);

        try {
            await store.dispatch("app-whitelabel-templates/updateTemplate", {
                template: updatedTemplate,
                toast,
                path: "templateData.sportsBetting"
            });
        } catch (error) {
            showErrorToast(toast, "Error updating template", axiosErrorHandle(error));
        }
    };

    const fetchSportsBettingItems = (ctx, callback) => {
        loading.value = true;

        store
            .dispatch("gm-sports-betting/fetchSportsBettingItems", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                whitelabelId: store.state.whitelabelCurrencyNabvar.whitelabel._id,
                templateId: editTemplate.value._id,
            })
            .then((r) => {
                loading.value = false;
                const {response, total} = r.data;
                callback(response);
                totalSportsBettingItems.value = total;
            })
            .catch((error) => {
                loading.value = false;
                showErrorToast(
                    toast,
                    "Error fetching Sports Betting list",
                    axiosErrorHandle(error)
                );
            });
    };

    watch([currentPage, perPage, searchQuery, recordDeleted], () => {
        refetchData();
    });

    return {
        isSelected,
        fetchSportsBettingItems,
        perPage,
        currentPage,
        totalSportsBettingItems,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refSportsBettingListTable,
        refetchData,
        recordDeleted,
        loading,
        selectSportsBettingItem,
    };
}
