<script>
import {computed} from "@vue/composition-api";
import store from "@/store";

export default {
  name: "GodMoneySlotGames",
  setup() {
    const editTemplate = computed(
        () => store.state["app-whitelabel-templates"].editTemplate
    );

    const slotGamesConfig = computed(
        () => editTemplate.value.templateData.slotGames
    );

    return {
      slotGamesConfig,
    };
  },
  data() {
    return {
      swiperGames: null,
    };
  },
  methods: {
    nextSlideGames() {
      this.swiperGames.slideNext();
    },
    prevSlideGames() {
      this.swiperGames.slidePrev();
    },
  },
  mounted() {
    this.swiperGames = new this.$root.$swiper(this.$refs.swiperGames, {
      slidesPerView: 3,
      slidesPerGroup: 1,
      spaceBetween: 20,
      speed: 800,
      loopFillGroupWithBlank: true,
      loop: true,
      breakpoints: {
        992: {
          slidesPerView: 5,
          slidesPerGroup: 5,
        },
      },
    });
  },
};
</script>

<template>
  <div class="w-full lg:w-11/12 lg:mx-auto">
    <div class="slot-text px-3 mb-16 text-lg font-bold text-white uppercase lg:text-3xl">
      <span>{{ slotGamesConfig.title }}</span>
    </div>

    <div ref="swiperGames" class="swiper gm-swiper-games" style="height: 250px">
      <div @click="prevSlideGames" class="swiper-prev">
        <svg viewBox="0 0 32 32">
          <path
              d="M11.386 3l13 13-13 13-3.772-3.772 9.228-9.228-9.228-9.228z"
          ></path>
        </svg>
      </div>
      <div class="swiper-wrapper">
        <div
            class="swiper-slide"
            v-for="(game, index) in slotGamesConfig.games"
            :key="index"
        >
          <img :src="game.src" alt="slots-games-image"/>
        </div>
      </div>
      <div @click="nextSlideGames" class="swiper-next">
        <svg viewBox="0 0 32 32">
          <path
              d="M11.386 3l13 13-13 13-3.772-3.772 9.228-9.228-9.228-9.228z"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.slot-text {
  font-family: var(--body-font), sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: 64px !important;
}

.gm-swiper-games {
  padding: 0 12px;

  .swiper {
    &-prev,
    &-next {
      position: absolute;
      top: 50%;
      height: 65%;
      transform: translateY(-50%);
      width: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 10;
      background: var(--para-alt-color);
      transition: 0.2s;

      &:hover {
        background: var(--para-alt-color);
      }

      svg {
        width: 30px;
        height: 30px;
        fill: var(--primary-color);
      }
    }

    &-prev {
      left: -12px;

      svg {
        transform: rotate(180deg);
      }
    }

    &-next {
      right: -12px;
    }

    &-slide {
      border-radius: 8px;
      overflow: hidden;
      width: 200px;
      height: 200px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
