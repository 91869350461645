<script>
import GeneralEditColor
  from "@/views/components/whitelabel-templates/GodMoney/config/GeneralEdit/Colors/GeneralEditColor.vue";
import TemplateEditFavicon from "@/views/components/whitelabel-templates/common/TemplateEditFavicon.vue";
import ContactEdit from "@/views/components/whitelabel-templates/GodMoney/config/GeneralEdit/Contact/ContactEdit.vue";
import ExtraEdit from "@/views/components/whitelabel-templates/GodMoney/config/GeneralEdit/extra/ExtraEdit.vue";

export default {
  name: "AppGEdit",
  components: {ExtraEdit, ContactEdit, TemplateEditFavicon, GeneralEditColor},
  data() {
    return {
      menu: [
        {name: "Color", collapsed: false},
        {name: "Favicon", collapsed: false},
        {name: "Contact Number", collapsed: false},
        {name: "Extra", collapsed: false},
      ],
    };
  },
  methods: {
    // preventCollapsedForAll() {
    //   this.edit = this.edit.map((item) => ({ ...item, collapsed: false }));
    // },

    expandMenu(index) {
      this.menu = this.menu.map((item, i) => ({
        ...item,
        collapsed: i === index ? !item.collapsed : false,
      }));
    },
  },
};
</script>

<template>
  <!--  <transition name="slide-x">-->
  <div
      class="card"
      style="
      width: fit-content;
      min-height: 200px;
      position: absolute;
      background: white;
      top: 15px;
      left: 70px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 12px;
      border-radius: 10px;
      cursor: pointer;
    "
  >
    <section
        v-for="(item, index) in menu"
        :key="index"
        @click="expandMenu(index)"
    >
      <div class="d-flex w-100 justify-content-between">
        <div>
          <h6 class="title mr-3">{{ item.name }}</h6>
        </div>
        <div>
          <feather-icon
              :icon="
              item.collapsed === true ? 'ChevronUpIcon' : 'ChevronRightIcon'
            "
              size="16"
          />
        </div>
      </div>
    </section>

    <general-edit-color v-if="menu[0].collapsed"></general-edit-color>

    <template-edit-favicon v-if="menu[1].collapsed"></template-edit-favicon>
    <contact-edit v-if="menu[2].collapsed"></contact-edit>
    <extra-edit v-if="menu[3].collapsed"></extra-edit>
  </div>
  <!--  </transition>-->
</template>

<style scoped lang="scss">
section {
  margin-inline: 10px;
}

.dark-layout {
  .card {
    background: #283046 !important;
  }
}

.slide-x-enter-active,
.slide-x-leave-active {
  transition: all 0.25s ease;
}

.slide-x-enter {
  transform: translateX(-15%);
}

.slide-x-leave-to {
  opacity: 0;
}
</style>
