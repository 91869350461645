<script>
import { computed } from "@vue/composition-api";
import store from "@/store";
import InlineSvg from "@/views/components/whitelabel-templates/common/InlineSvg.vue";

export default {
  name: "GodMoneySocialLinks",
  components: { InlineSvg },
  setup() {
    const editTemplate = computed(
      () => store.state["app-whitelabel-templates"].editTemplate
    );

    const socialLinksConfig = computed(
      () => editTemplate.value.templateData.socialLinks
    );

    return {
      socialLinksConfig,
    };
  },
};
</script>

<template>
  <div class="gm-socials">
    <div class="container flex items-center justify-between">
      <div class="font-bold text-white">
        <a>{{ socialLinksConfig.title }}</a>
      </div>

      <div class="flex justify-end gap-1">
        <a
          v-for="(link, index) in socialLinksConfig.links"
          :key="index"
          class="px-1"
          :href="link.href"
        >
          <inline-svg :svg-data="link.svgData" />
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.gm-socials {
  margin-top: 30px;
  background-color: #e8aa26;
}
</style>
