import axios from '@axios'

const APP_URL = process.env.VUE_APP_URL

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchLiveTablesItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/liveTables`, {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        removeLiveTablesItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${APP_URL}/liveTables/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        addLiveTablesItem(ctx, liveTablesData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${APP_URL}/liveTables`,  liveTablesData )
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        updateLiveTablesItem(ctx, { id, liveTablesData }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${APP_URL}/liveTables/${id}`, liveTablesData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
