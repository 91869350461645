var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t("labels.show")))]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v(_vm._s(_vm.$t("labels.entries")))])],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('div',[_c('b-button',{attrs:{"variant":"primary","to":{
                  name: 'gm-lobby-form-create',
                  query: {
                    edit: false,
                    templateId: _vm.templateID,
                    templateType: _vm.templateType,
                  },
                }}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t("buttons.add")))])])],1)],1)])],1)],1),_c('b-overlay',{staticClass:"p-50",attrs:{"show":_vm.loading,"variant":_vm.$store.state.appConfig.layout.skin,"blur":"2"}},[_c('b-table',{ref:"refLobbyListTable",staticClass:"position-relative",staticStyle:{"min-height":"300px"},attrs:{"items":_vm.fetchLobby,"responsive":"","fields":_vm.tableColumns,"primary-key":"_id","sort-by":_vm.sortBy,"show-empty":"","empty-text":this.$t('messages.nomatching'),"sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('tooltips.edit')),expression:"$t('tooltips.edit')",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle mr-1",attrs:{"variant":"primary","size":"sm","to":{
              name: 'gm-lobby-form',
              params: { id: data.item._id },
              query: {
                edit: true,
                templateId: _vm.templateID,
                templateType: _vm.templateType,
              },
            }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('tooltips.delete')),expression:"$t('tooltips.delete')",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.removeLobby(data.item._id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)]}}])})],1),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("labels.showing"))+" "+_vm._s(_vm.dataMeta.from)+" "+_vm._s(_vm.$t("labels.to"))+" "+_vm._s(_vm.dataMeta.to)+" "+_vm._s(_vm.$t("labels.of"))+" "+_vm._s(_vm.dataMeta.of)+" "+_vm._s(_vm.$t("labels.entries")))])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalLobby,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }