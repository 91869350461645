import { render, staticRenderFns } from "./GodMoneyFooter.vue?vue&type=template&id=46196640&scoped=true"
import script from "./GodMoneyFooter.vue?vue&type=script&lang=js"
export * from "./GodMoneyFooter.vue?vue&type=script&lang=js"
import style0 from "./GodMoneyFooter.vue?vue&type=style&index=0&id=46196640&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46196640",
  null
  
)

export default component.exports