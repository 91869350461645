<script>
import GodMoneyNav from "@/views/components/whitelabel-templates/GodMoney/components/common/navMenu/GodMoneyNav.vue";
import GodMoneyRegister
  from "@/views/components/whitelabel-templates/GodMoney/components/modules/auth/register/GodMoneyRegister.vue";
import GodMoneyLogin
  from "@/views/components/whitelabel-templates/GodMoney/components/modules/auth/login/GodMoneyLogin.vue";
import GodMoneySideNav
  from "@/views/components/whitelabel-templates/GodMoney/components/common/navMenu/GodMoneySideNav.vue";

export default {
  name: "GodMoneyHeader",
  components: {GodMoneySideNav, GodMoneyRegister, GodMoneyNav, GodMoneyLogin},

  props: ["header", "menu", "appNavKey"],

  data() {
    return {
      isMenuOpen: false,
    };
  },

  computed: {
    showLogo() {
      return this.header.logo && !this.header.logo.hide;
    },
  },

  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

<template>
  <header class="gm-header w-100">
    <div class="gm-header-left">
      <!-- burger -->
      <div class="d-flex align-items-center">
        <god-money-side-nav v-if="menu" :menu="menu"/>

        <a v-if="showLogo" class="logo">
          <img :src="header.logo.href" alt="header-logo"/>
        </a>

        <p v-else class="logo">
          LOGO
        </p>
      </div>
    </div>

    <div class="gm-header-links">
      <god-money-nav v-if="menu" :menu="menu" :key="appNavKey"/>
    </div>

    <div class="gm-header-right">
      <div class="d-flex align-items-center">
        <god-money-register v-if="!header.auth.hide" :config="header.auth" class="mr-2"/>
        <god-money-login
            v-if="!header.auth.hide"
            :config="header.auth"
            ref="userLogin"
        />
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.gm-header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 50px;
  gap: 10px;
  border-bottom: 1px solid var(--header-c) !important;
  color: var(--header-c);
  background-color: color-mix(
          in srgb,
          var(--header-bg) 100%,
          transparent 80%
  );
  position: fixed;

  &-left {
    display: flex;
    align-items: center;
    gap: 50px;

    .logo {
      height: 40px;
      width: 150px;
      display: flex;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-links {
    position: fixed;
    transform: translateX(-200%);
    transition: .5s;

    @media (min-width: 1024px) {
      position: static;
      width: 100%;
      height: 100%;
      transform: translateX(0);
    }

  }

  &-right {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
</style>

